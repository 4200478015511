import { calculateRangeSlider } from "./range-slider.js";

const $ = jQuery;

/** Returns whether the "Moving out" cleaning type is selected or not. This is important because prices are
 *  higher for moving out cleanings */
export function isMovingOutSelected() {
    return $("#multiplier").val() === "moving_out";
}

/** Returns whether the "In-Depth" cleaning type is selected or not. This is important because prices are
 *  higher for in-depth cleanings */
export function isInDepthSelected() {
    return $("#multiplier").val() === "seasonal_or_annual";
}

/** Returns the DOM element for the loader (spinner) that shows loading status */
export function getSummaryLoader() {
    return $(".booking__summary__loader");
}

/** Returns the DOM element for the button that directs the user to the payment screen */
export function getFinalizeButton() {
    return $("#cleaning .booking__submit button");
}

/** Returns what step of the booking form we're currently at.
 *  Possible values: "step1", "step2" */
export function getBookingFormStatus() {
    return $("#cleaning").is(":visible") ? "step1" : "step2";
}

/** Returns all input DOM elements that need refreshing if a value changes, usually because they depend on values from
 *  the backend, such as the price */
export function getInputsThatNeedRefreshing() {
    return $(
        [
            "#home_size",
            "#multiplier",
            ".booking__content__list input",
            ".booking__content__list select",
            ".booking__products input",
            "#booking_hours",
        ].join(", "),
    );
}

/** Returns items in the "Overview" section in the summary component */
export function getBookingOverviewItems() {
    return $(".booking__overview").find(".booking__overview__items");
}

/** Returns the container of the summary component */
export function getBookingSummary() {
    return $(".booking__summary, .booking__summary--step2");
}

/** Refreshes the hours needed for the cleaning */
export function setBookingHours(dataSet) {
    const bookingHours =
        dataSet.finalItems.find((elem) => elem.name === "Recommended hours")?.value || 0;
    $("#booking__hours__text").text(bookingHours);
    const input = $('input[name="recommended_hours"]');
    input.val(bookingHours);
}

/** Refreshes the appropriate inputs with fresh data from the backend */
export function refreshInputs($element, manual = false) {
    if ($element.attr("type") === "range") {
        if ($element.attr("name") === "sq") {
            var currentBaseService = baseServices[$element.val()];
            console.log({ currentBaseService, val: $element.val(), baseServices });
            var label = $('label[for="' + $element.attr("id") + '"]');
            if (manual === true) {
                const baseServiceItem = backendVars.summaryData.finalItems.find(
                    (elem) => elem.name === "Cleaning",
                );
                if (baseServiceItem) {
                    currentBaseService = baseServices.find(
                        (elem) =>
                            elem.price === baseServiceItem.price ||
                            elem.movingOutPrice === baseServiceItem.price,
                    );
                    $element.val(currentBaseService.id);
                }
            }

            let price = currentBaseService.price;

            if (isMovingOutSelected() && currentBaseService.movingOutPrice)
                price = currentBaseService.movingOutPrice;
            // Uncomment this if you want the displayed price to be multiplied for home size when in-depth is selected
            else if (isInDepthSelected()) price = currentBaseService.inDepthPrice;

            label.find(".range__text").html(currentBaseService.name_html);
            label.find(".range__value").text(price + "€"); // TODO: Fordítás szempontjából nem a legjobb még!
        } else if ($element.attr("name") === "booking_hours") {
            var extraHours = $element.val() / 2;

            if (extraHours <= 0) {
                extraHours = "No"; // TODO: Fordítás szempontjából nem a legjobb még!
            }

            $("#booking__extra__hours__text").text(extraHours);
        }

        calculateRangeSlider($element);
    }
}

export function addCssRule(rule) {
    document.head.appendChild(document.createElement("style")).innerHTML = rule;
}
